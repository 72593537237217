<template>
    <div id="main">
        <div id="bingoCard" ref="bingoCard" :class="cardInactive">
            <div id="header" :class="isCardElementsInActive">
                <div class="squareHeader headerLeftTopCorner red"><p class="headerText">B</p></div>
                <div class="squareHeader blue"><p class="headerText">I</p></div>
                <div class="squareHeader yellow"><p class="headerText">N</p></div>
                <div class="squareHeader green"><p class="headerText">G</p></div>
                <div class="squareHeader headerRightTopCorner pink"><p class="headerText">O</p></div>
            </div>
            <div id="numbers" :class="isCardElementsInActive">
                <div class="column" v-for="i in 5" :key="'column=' + i">
                    <div class="squareContainer" v-for="j in 5" :key="'container='+j">
                        <div v-if="editor"
                            class="square"
                            title="Select Figure"
                            :class="{
                                'figure' :checkIfMiddleSquare(i,j) ? figure.includes(getSquarePosition(i,j)) : false,
                                'clickable' :checkIfMiddleSquare(i,j) ? true : false,
                                'leftBottomCorner': getSquarePosition(i,j) === 4,
                                'rightBottomCorner': getSquarePosition(i,j) === 23
                            }"
                            v-on:click="checkIfMiddleSquare(i,j) && onClick(getSquarePosition(i,j))">
                            <p v-if="checkIfMiddleSquare(i,j)" id="square-text">{{getSquareNumber(i,j)}}</p>
                        </div>
                        <div
                            v-else-if="!editor"
                            class="square"
                            title="Select Number"
                            :class="{
                                'figure' :checkIfMiddleSquare(i,j) ? figure.includes(getSquarePosition(i,j)) : false,
                                'clickable' :enable && checkIfMiddleSquare(i,j) ? true : false,
                                'leftBottomCorner': getSquarePosition(i,j) === 4,
                                'rightBottomCorner': getSquarePosition(i,j) === 23
                            }"
                            v-on:click="checkIfMiddleSquare(i,j) && onClick(getSquarePosition(i,j), cardNumber)">
                            <p v-if="checkIfMiddleSquare(i,j)" id="square-text">{{numbers[getSquarePosition(i,j)]}}</p>
                            <div id="selected" v-if="checkIfMiddleSquare(i,j) ? selectedNumbers.includes(getSquarePosition(i,j)) : false"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from '../../store'
import { } from 'vue'
import bingoTypes from '../../store/modules/bingo/types'
import { mapGetters } from 'vuex'
export default {
    props: {
        editor: Boolean,
        figure: Array,
        // used for in game
        numbers: Array,
        cardNumber: Number,
        selectedNumbers: Array,
        enable: Boolean
    },
    data: function () {
        return {
            inactive: true,
            color1: this.getRandomColor(),
            color2: this.getRandomColor()
        }
    },
    computed: {
        ...mapGetters('bingo', [
            'getFakeChallenges',
            'getBingoBalls'
        ]),
        isCardInActive () {
            if (this.editor) return false
            return this.inactive
        },
        amountOfFakeChallenges () {
            return this.getFakeChallenges[this.cardNumber]
        },
        gameIsActive () {
            return this.getBingoBalls.length > 0
        },
        cardInactive () {
            return this.isCardInActive ? 'animate__animated animate__fadeIn animate__delay-1s inactive' : 'animate__animated animate__fadeIn animate__delay-1s active'
        },
        isCardElementsInActive () {
            return this.isCardInActive ? 'inactive' : 'active'
        }
    },
    watch: {
        gameIsActive: function () {
            this.inactive = false
        },
        amountOfFakeChallenges: function () {
            if (this.amountOfFakeChallenges >= 2) {
                this.inactive = true
            }
        },
        isCardInActive: function () {
            this.$refs.bingoCard.style.background = `radial-gradient(circle, ${this.isCardInActive ? this.color1 + '50' : this.color1} 0%, ${this.isCardInActive ? this.color2 + '50' : this.color2} 100%)`
        }
    },
    beforeMount () {
        window.addEventListener('resize', this.changeWitdh)
    },
    mounted () {
        this.$refs.bingoCard.style.background = `radial-gradient(circle, ${this.isCardInActive ? this.color1 + '50' : this.color1} 0%, ${this.isCardInActive ? this.color2 + '50' : this.color2} 100%)`
        this.changeWitdh()
        setTimeout(() => {
            if (this.enable) {
                this.inactive = false
            }
        }, 10)
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.changeWitdh)
    },
    methods: {
        changeWitdh () {
            this.$refs.bingoCard.style.width = `${this.$refs.bingoCard.clientHeight / 1.2}px`
        },

        getRandomColor () {
            const colors = ['#EF413D', '#00AFC8', '#FFCB05', '#00AE4D', '#D94B9B']
            return colors[Math.round(Math.random() * 4)]
        },
        onClick (position, cardNumber) {
            if (!this.isCardInActive) {
                if (this.editor) {
                    this.$emit('figure', position)
                } else {
                    store.dispatch('bingo/' + bingoTypes.TOGGLE_NUMBER, { cardNumber: cardNumber, position: position })
                }
            }
        }
    },
    setup (props, { emit }) {
        const checkIfMiddleSquare = (column, row) => {
            // (((i * 5) + j) - 5) = column times 5 + row minus 5 so it begin with 1
            return ((column * 5) + row) - 5 !== 13
        }

        const getSquarePosition = (column, row) => {
            // numbers[((i * 5) + j) - 5 > 13 ? ((i * 5) + j) - 7 : ((i * 5) + j) - 6] is for displaying the numbers of the bingoCard
            // when the first calculation is above 13 we need to compensate for the middle empty square
            return ((column * 5) + row) - 5 > 13 ? ((column * 5) + row) - 7 : ((column * 5) + row) - 6
        }

        const getSquareNumber = (column, row) => {
            return ((column * 5) + row) - 5 > 13 ? ((column * 5) + row) - 6 : ((column * 5) + row) - 5
        }

        return {
            checkIfMiddleSquare, getSquarePosition, getSquareNumber
        }
    }
}
</script>
<style scoped>
    #main{
        width: 100%;
        height: 100%;
    }

    #bingoCard{
        height: 95%;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translate(-50%, 2.5%);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    }

    #header{
        flex: 1;
        display: flex;
        height: fit-content;
    }

    .red{
        background-color: #EF413D;
    }

    .blue{
        background-color: #00AFC8;
    }

    .yellow{
        background-color: #FFCB05;
    }

    .green{
        background-color: #00AE4D;
    }

    .pink{
        background-color: #D94B9B;
    }

    .squareHeader{
        margin: auto;
        width: 100%;
        height: 100%;
        position: relative;
        user-select: none;
    }

    .headerLeftTopCorner{
        border-top-left-radius: 20px;
    }

    .headerRightTopCorner{
        border-top-right-radius: 20px;
    }

    .leftBottomCorner{
        border-bottom-left-radius: 20px !important;
    }

    .rightBottomCorner{
        border-bottom-right-radius: 20px !important;
    }

    .headerText{
        margin: 0;
        text-align: center;
        font-size: 30px;
        position: relative;
        color: white;
        left: 0%;
        top: 50%;
        transform: translateY(-50%);
    }

    #numbers{
        flex: 9;
        display: flex;
        flex-direction: row;
    }

    #bingoCard-Title{
        font-size: calc(100% / 0.23);
        margin:0;
    }

    .column{
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .squareContainer{
        flex: 1;
    }

    .square{
        margin: auto;
        margin-top: 5%;
        width: 90%;
        height: 90%;
        background-color: rgba(255,255,255,0.2);
        border-radius: 5px;
        position: relative;
    }

    .figure{
        background-color: rgba(32, 0, 252, 0.30);
    }

    .clickable:hover{
        cursor: pointer;
    }

    #square-text{
        margin: 0;
        text-align: center;
        font-size: 30px;
        position: relative;
        color: white;
        left: 0%;
        top: 50%;
        transform: translateY(-50%);
        user-select: none;
        text-shadow: 0px 0px 7px #000000;
    }

    #selected{
        height: 100%;
        width: 100%;
        background-color: rgba(24, 250, 255, 0.7);
        border-radius: 100%;
        position: absolute;
        top: 0;
    }

    .inactive{
        opacity: 0.5;
    }

    .active{
        opacity: 1;
    }
</style>
