<template>
    <div id="container">
        <transition
            enter-active-class="animate__animated animate__slideInDown animate__faster"
            leave-active-class="animate__animated animate__slideOutUp animate__faster"
            mode="out-in"
        >
            <div id="errorMessage" v-if="message != ''">
                <p class="errorText">{{ message }}</p>
            </div>
        </transition>
        <div id="creatorContainer"> <!-- TODO: ADD when there are More games: class="animate__animated animate__slideInDown animate__faster" -->
            <form @submit="submited" class="generalContainer">
                <div class="headerContainer">
                    <h2 class="headerText">Create your game</h2>
                    <p class="headerDescription">Pick your game and username and select the options you want!</p>
                </div>
                <div class="flex">
                    <!-- <div id="gameSelector">
                        <p class="gameSelectorLabel">game</p>
                        <select id="games" v-model="game" name="games"> TODO: ADD when there are More games: class="animate__animated animate__fadeInDown animate__delay-1s animate__faster"
                            <option disabled value="empty">selected game</option>
                            <option  v-for="game in getGameNames()" :key="game" :value="game">{{game}}</option>
                        </select>
                    </div> -->
                    <div id="nameSelector">
                        <p class="nameSelectorLabel">Pick Your username!</p>
                        <input id="name" type="text" v-model="username" name="name" placeholder="Username"/> <!-- TODO: ADD when there are More games: class="animate__animated animate__fadeInDown animate__delay-1s animate__faster" -->
                    </div>
                </div>
            </form>
        </div>
        <transition
                enter-active-class="animate__animated animate__zoomIn animate__faster"
                leave-active-class="animate__animated animate__zoomOut animate__faster"
                mode="out-in"
            >
            <div class="specificGameForm" v-if="game === 'bingo'">
                <BingoForm @created-game="handleCreate"/>
            </div>
        </transition>
    </div>
</template>
<script>
import BingoForm from '../components/forms/BingoForm'
import { ref, computed } from 'vue'
import { mapGetters } from 'vuex'
import generalTypes from '../store/types'
import chatTypes from '../store/modules/chat/types'
import gameTypes from '../store/modules/game/types'
import store from '../store'
import router from '../router'

export default {
    components: {
        BingoForm
    },
    mounted () {
        store.dispatch(`game/${gameTypes.GET_GAME_NAMES}`)
    },
    methods: {
        submited (e) {
            e.preventDefault()
        }
    },
    setup () {
        const username = ref()
        const game = ref('bingo') // TODO: CHANGE when there are More games: empty
        const message = ref('')
        const getters = computed(() => {
            return {
                ...mapGetters('game', [
                    'getGameNames'
                ])
            }
        })

        const handleCreate = (values) => {
            if (username.value === undefined || username.value === '') {
                message.value = 'You need to fill in your username'
                setTimeout(() => {
                    message.value = ''
                }, 2000)
                return
            }
            values.userName = username.value
            store.dispatch(`game/${gameTypes.SET_GAMETYPE}`, game.value)
            store.dispatch(`${game.value}/${generalTypes.ONMOUNT}`)
            store.dispatch(`chat/${chatTypes.ONMOUNT}`)
            store.dispatch(`${game.value}/${generalTypes.CREATE_GAME}`, values)
        }

        store.watch(
            (state, getters) => getters[`${game.value}/getGamePin`],
            (newVal, oldVal) => {
                if (newVal !== undefined) {
                    router.push(`/${game.value}`)
                }
            }
        )

        return {
            ...getters.value, username, game, handleCreate, message
        }
    }
}
</script>

<style scoped>
    #container{
        width: 80vw;
        margin: auto;
        box-shadow: 0px 0px 30px black;
        background-color: rgba(0, 0, 0, 0.096);
        backdrop-filter: blur(5px);
        height: fit-content;
        min-height: 100vh;
        border: 0;
        overflow: hidden;
    }

    .headerContainer{
        width: inherit;
        padding-top: 25px;
        padding-bottom: 10px;
        background-color: rgba(0, 0, 0, 0.089);
        box-shadow: 0px 3px 20px black;
    }

    .generalContainer{
        background-color: rgba(0, 0, 0, 0.178);
        box-shadow: 0px 3px 20px black;
    }

    .headerText{
        color: rgb(230, 230, 230);
        text-align: center;
        font-size: 30px;
        margin-bottom: 0px;
    }

    .gameSelectorLabel{
        color: rgb(230, 230, 230);
        text-align: center;
        font-size: 20px;
        margin: 0px;
        height: 30px;
    }

    .nameSelectorLabel{
        color: rgb(230, 230, 230);
        text-align: center;
        font-size: 20px;
        margin: 0px;
        height: 30px;
        width: fit-content;
        margin: auto;
    }

    #creatorContainer{
        height: fit-content;
        margin-bottom: 2.0vh;
        --animate-duration: 1.5s;
    }

    .headerDescription{
        color: rgb(230, 230, 230);
        text-align: center;
        font-size: 25px;
        margin-top:5px
    }

    .specificGameForm{
        margin: auto;
        height: fit-content;
        margin-top: 2.0vh;
        margin-bottom: 2.0vh;
        --animate-duration: 1.5s;
    }

    #gameSelector{
        text-align: center;
        line-height: 60px;
        flex:1
    }

    #nameSelector{
        text-align: center;
        line-height: 60px;
        flex: 1;
    }

    #name{
        text-align: center;
    }

    #seperator{
        width: 90%;
        border: 1px solid black;
        margin: auto;
        margin-top: 0;
        margin-bottom: 0;
    }

    .flex{
        display: flex
    }

    input[type=text], select {
        width: 250px;
        height: 30px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }

    #errorMessage{
        position: fixed;
        width: inherit;
        height: 40px;
        text-align: center;
        background-color: rgba(255, 0, 0, 0.308);
        box-shadow: 0px -10px 30px rgba(37, 37, 37, 0.541);
        z-index: 1000;
    }

    .errorText{
        margin: 0;
        color: rgb(230, 230, 230);
        font-size: 25px;
        line-height: 40px;
    }
</style>
