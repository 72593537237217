<template>
    <div id="flex formContainer">
        <form v-on:submit="onSubmit">
            <div class="submitButtonContainer">
                <button id="submitButton"  type="submit" title="Create bingo"><svg xmlns="http://www.w3.org/2000/svg" class="checkmark" viewBox="0 0 512 512"><title>Create bingo</title><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"/></svg></button>
            </div>
            <div class="paddingTop flex input-height input">
                <label class="flex-1 input-label" for="amountOfUsers">amount of users: </label>
                <input class="flex-2" type="range" v-model="users" name="amountOfUsers" min="2" max="100" step="1"/>
                <label class="flex-1 input-label" for="amountOfUsers">{{ users }}</label>
            </div>
            <!-- <div class="flex input-height input">
                <label class="flex-1 input-label" for="amountOfCards">amount of cards: </label>
                <input class="flex-2" type="range" v-model="cards" name="amountOfCards" min="1" max="2" step="1"/>
                <label class="flex-1 input-label" for="amountOfCards">{{ cards }}</label>
            </div> -->
            <div class="flex input-height input">
                <label class="flex-1 input-label" for="vertical">Admin as player: </label>
                <input class="flex-2 input-checkbox-vertical-align" type="checkbox" v-model="player" name="vertical"/>
                <div class="flex-1 input-label"></div>
            </div>
            <div class="flex paddingTop input-height input">
                <label class="flex-1 input-label" for="vertical">Vertical line: </label>
                <input class="flex-2 input-checkbox-vertical-align" type="checkbox" v-model="vertical" name="vertical"/>
                <div class="flex-1 input-label"></div>
            </div>
            <div class="flex paddingTop input-height input">
                <label class="flex-1 input-label" for="horizontal">Horizontal line: </label>
                <input class="flex-2 input-checkbox-vertical-align" type="checkbox" v-model="horizontal" name="horizontal"/>
                <div class="flex-1 input-label"></div>
            </div>
            <div class="flex paddingTop input-bingoCard-height input">
                <label class="flex-1 input-label" for="figure">Figure: </label>
                <div class="flex-2">
                    <BingoCard @figure="handleFigure" :figure="figure" :editor="true"/>
                </div>
                <div class="flex-1 input-label"></div>
            </div>
        </form>
    </div>
</template>

<script>
import { ref } from 'vue'
import BingoCard from '../bingoCard/BingoCard'
import BingoTypes from '../../store/modules/bingo/types'
import store from '../../store'

export default {
    components: {
        BingoCard
    },
    setup (props, { emit }) {
        const users = ref(20)
        const cards = ref(1)
        const vertical = ref(false)
        const horizontal = ref(false)
        const figure = ref([])
        const player = ref(false)

        const handleFigure = (position) => {
            if (figure.value.includes(position)) {
                figure.value = figure.value.filter((val, index) => {
                    return val !== position
                })
            } else {
                figure.value.push(position)
            }
        }

        const onSubmit = (e) => {
            e.preventDefault()
            figure.value.sort((a, b) => { return a >= b })
            const values = {}
            values.users = users.value
            values.cards = cards.value
            values.vertical = vertical.value
            values.horizontal = horizontal.value
            values.figure = figure.value
            store.dispatch(`bingo/${BingoTypes.SET_PLAYER}`, player)
            emit('created-game', values)
        }

        return { users, cards, horizontal, vertical, handleFigure, figure, onSubmit, player }
    }
}
</script>

<style scoped>
    .flex{
        display: flex;
    }

    .flex-1{
        flex: 1;
    }

    .flex-2{
        flex:2;
    }

    .flex-3{
        flex:3;
    }

    .paddingTop{
        padding-top: 20px;
    }

    .input-height{
        height: 60px;
    }

    .checkmark{
        width: 40px;
        margin-top: 2px;
    }

    .input-label{
        line-height: 60px;
        text-align: center;
        font-size: 20px;
        color: rgb(230, 230, 230);
        user-select: none;
    }

    .submitButtonContainer{
        position: absolute;
        right: 25px;
    }

    .input-checkbox-vertical-align{
        position: relative;
        top: 20px;
    }

    .input-bingoCard-height{
        height: 500px;
    }

    .input{
        width: 90%;
        margin: auto;
    }

    #submitButton{
        width: 50px;
        height: 50px;
        margin: auto;
        border-radius: 100%;
        border: none;
        padding: 0px;
        background-color: #00ff407e;
        color: white;
        font-size: 15px;
        border: none;
        outline: none;
        transition: all .2s ease-in-out;
    }

    #submitButton:hover{
        background-color: #00ff4098;
        transform: scale(1.2);
        cursor: pointer;
    }

</style>
